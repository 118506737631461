<template>
  <div class="contacts-page">
    <div
      v-if="$can('create', permissionModule )"
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="addContactModal()"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>
    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <b-table
      id="contacts-table"
      class="mt-4 table-contact details-table"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      small
      no-border-collapse
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        v-if="$can('delete', permissionModule )"
        #head(select)
      >
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('system/contact', 'Contacts')"
        />
      </template>
      <template
        v-if="$can('delete', permissionModule )"
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: 'system/contact', fetchTable: 'Contacts' })"
        />
      </template>
      <template #cell(avatar)="row">
        <b-avatar
          :text="row.item.name | avatarText"
          size="34px"
          :variant="row.item.color"
        />
      </template>
      <template #cell(detail)="row">
        <b-button
          variant="primary"
          class="btn-table"
          @click="modalContact(row.item)"
        >
          <VSIcons
            name="UserBold"
            color="#FFF"
            width="18"
            height="18"
          />
        </b-button>
      </template>
      <template #cell(actions)="row">
        <b-dropdown
          no-caret
          right
          variant="light"
          menu-class="dropdown-table-actions"
          toggle-class="button-dropdown-actions"
        >
          <template #button-content>
            <VSIcons
              name="Actions"
              width="5"
              height="22"
            />
          </template>
          <b-dropdown-item
            v-if="$can('update', permissionModule )"
            class="dropdown-table-item"
            @click="openModal(row.item)"
          >
            <VSIcons
              name="Edit"
              width="20"
              height="20"
              color="#7D8E9E"
            />
            <span>
              Editar
            </span>
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            v-if="$can('delete', permissionModule )"
            class="dropdown-table-item"
            @click="deleteItem(row.item)"
          >
            <VSIcons
              name="TrashSquare"
              width="21"
              height="21"
              color="#7D8E9E"
            />
            <span>
              Excluir
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="contacts-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      name="AddNewContact"
      width="670"
      :scrollable="true"
      :properties="properties"
    />
    <VSModal
      name="ContactDetails"
      width="470"
      height="auto"
      :properties="contactSelected"
    />
    <VSModal
      name="EditContact"
      width="670"
      :properties="properties"
    />
    <VSModal
      name="DeleteOneItem"
      width="670"
      :properties="itemToDelete"
    />
  </div>
</template>

<script>
import {
  BDropdown, BInputGroup, BInputGroupPrepend, BFormInput, BTable, BButton, BFormCheckbox, BSpinner, BPagination, BAvatar, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'
import { avatarText } from '@/@core/utils/filter'

export default {
  components: {
    VSModal, BDropdown, BInputGroup, BInputGroupPrepend, BFormInput, BTable, BButton, VSIcons, BFormCheckbox, BSpinner, BPagination, BAvatar, BDropdownItem, BDropdownDivider,
  },
  filters: {
    avatarText,
  },
  mixins: [
    tableConfig,
  ],
  props: {
    relation: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      itemToEdit: {},
      itemToDelete: { api: 'system/contact', fetchTable: 'Contacts' },
      properties: {},
      contactSelected: {},
      fields: [
        {
          label: '',
          key: 'select',
          tdClass: 'align-middle pr-0',
          thClass: 'pr-0',
        },
        {
          label: '',
          key: 'avatar',
          tdClass: 'align-middle pr-0 pl-0',
        },
        {
          label: 'Nome',
          key: 'name',
          sortable: true,
          tdClass: 'align-middle pl-0',
        },
        {
          label: 'E-mail',
          key: 'email',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Cargo',
          key: 'job_position',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Departamento',
          key: 'department',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Observações',
          key: 'note',
          sortable: true,
          tdClass: 'align-middle text-truncate',
        },
        {
          label: 'Telefone',
          key: 'detail',
          tdClass: 'align-middle',
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'align-middle',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      apiInCall: 'app/getApiInCall',
    }),
    permissionModule() {
      if (this.relation === 'licensingCondition_id') { return 'licensesconditions' }
      if (this.relation === 'agency_id') { return 'licensingagency' }
      return this.relation.split('_')[0]
    },
  },
  methods: {
    ...mapMutations({
      setItensToDelete: 'app/SET_ITENS_DELETE',
    }),
    modalContact(item) {
      this.contactSelected = item
      this.showModal('ContactDetails')
    },
    editContact(row) {
      this.itemToEdit = row
      this.showModal('EditContact')
    },
    openModal(row) {
      this.properties.item = row
      this.properties.relation = this.relation
      this.showModal('EditContact')
    },
    addContactModal() {
      this.properties.relation = this.relation
      this.showModal('AddNewContact')
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-table{
  border-radius: 8px;
  width: 34px;
  height: 34px;
  padding: 4px;
}

.table-contact {
  min-height: 200px !important;
}
</style>
