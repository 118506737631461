<template>
  <PublicAgenciesDetails />
</template>

<script>
import PublicAgenciesDetails from '@/modules/public-agencies/public-agencies-details/PublicAgenciesDetails.vue'

export default {
  components: { PublicAgenciesDetails },
}
</script>
