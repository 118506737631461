<template>
  <div>
    <div
      v-if="$can('update', 'licensingagency')"
      class="btn-actions-content d-flex"
    >
      <b-button
        v-if="edit"
        class="d-flex align-items-center mr-3 button-cancel"
        variant="outline-primary"
        @click="cancel()"
      >
        <VSIcons
          name="CloseSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Cancelar</span>
      </b-button>
      <b-button
        v-if="!edit"
        class="btn-grad d-flex align-items-center"
        @click="setEdit(true)"
      >
        <VSIcons
          name="Edit"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Editar</span>
      </b-button>
      <b-button
        v-else
        class="btn-grad d-flex align-items-center"
        @click="saveChanges(licensingAgencyData)"
      >
        <VSIcons
          name="TickSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Salvar</span>
      </b-button>
    </div>
    <div v-if="!apiInCall">
      <validation-observer ref="form">
        <h6>Dados do órgão licenciador</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="cnpj"
                rules="cnpj"
              >
                <b-form-group label="CNPJ">
                  <b-input-group>
                    <b-form-input
                      id="cnpj"
                      v-model="licensingAgencyData.cnpj"
                      v-mask="'##.###.###/####-##'"
                      type="text"
                      :disabled="!edit"
                      @blur="getDataCompanyByCnpj(licensingAgencyData.cnpj)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <validation-provider
                v-slot="{ errors }"
                name="nome"
                rules="required|max:100"
              >
                <b-form-group label="Nome">
                  <b-input-group>
                    <b-form-input
                      id="nome"
                      v-model="licensingAgencyData.name"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="1">
              <validation-provider
                v-slot="{ errors }"
                name="sigla"
                rules="required|max:20"
              >
                <b-form-group label="Sigla">
                  <b-input-group>
                    <b-form-input
                      id="sigla"
                      v-model="licensingAgencyData.acronyms"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="nível"
                rules="required"
              >
                <b-form-group label="Competência">
                  <b-input-group>
                    <b-form-select
                      id="nivel"
                      v-model="licensingAgencyData.level"
                      :options="competenceOptions"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>

        <h6>Contato do órgão licenciador</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="telefone"
                rules="phone"
              >
                <b-form-group label="Telefone">
                  <b-input-group>
                    <b-form-input
                      id="telefone"
                      v-model="licensingAgencyData.telephone"
                      v-mask="maskTelephone(licensingAgencyData.telephone)"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">

              <b-form-group label="E-mail">
                <b-input-group>
                  <b-form-input
                    id="email"
                    v-model="licensingAgencyData.email"
                    type="email"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <h6>Endereço do órgao licenciador</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col md="2">
              <validation-observer ref="cep">
                <validation-provider
                  v-slot="{ errors }"
                  name="CEP"
                  rules="max:9|min:8"
                >
                  <b-form-group label="CEP">
                    <b-input-group>
                      <b-form-input
                        id="cep"
                        v-model="licensingAgencyData.zip_code"
                        :disabled="!edit"
                        type="text"
                        @blur="getAddress(licensingAgencyData.zip_code)"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </validation-observer>
            </b-col>
            <b-col md="2">
              <b-form-group label="Cidade">
                <b-input-group>
                  <b-form-input
                    id="cidade"
                    v-model="licensingAgencyData.city"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="UF">
                <b-input-group>
                  <b-form-input
                    id="uf"
                    v-model="licensingAgencyData.state"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Bairro">
                <b-input-group>
                  <b-form-input
                    id="bairro"
                    v-model="licensingAgencyData.neighborhood"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Endereço">
                <b-input-group>
                  <b-form-input
                    id="endereco"
                    v-model="licensingAgencyData.address"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Número / Complemento">
                <b-input-group>
                  <b-form-input
                    id="numero"
                    v-model="licensingAgencyData.number_address"
                    :disabled="!edit"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <b-spinner
      v-else
      variant="primary"
      class="general-data-spinner"
    />
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import {
  BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BButton, BSpinner, BFormSelect,
} from 'bootstrap-vue'
import { mapMutations, mapGetters } from 'vuex'
import { titleInverse } from '@core/utils/filter'
import VSIcons from '@/assets/icons/VSIcons.vue'
import licensingAgency from '@/services/licensingAgency'
import { required } from '@/@core/utils/validations/validations'
import { maskPhone } from '@/@core/mixins/ui/forms'

export default {
  components: {
    BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BButton, VSIcons, BSpinner, BFormSelect,
  },
  filters: {
    titleInverse,
  },
  mixins: [
    maskPhone,
  ],
  data() {
    return {
      competenceOptions: [
        'Federal', 'Estadual', 'Municipal', 'Outro',
      ],
      required,
      createAgencyLicensing: false,
      licensingAgencyData: {
        name: '',
        acronyms: '',
        cnpj: '',
        level: '',
        description: '',
        telephone: '',
        email: '',
        address: '',
        zip_code: '',
        number_address: '',
        neighborhood: '',
        city: '',
        state: '',
      },
    }
  },
  computed: {
    ...mapGetters({ edit: 'app/getItemTableEdit', apiInCall: 'app/getApiInCall' }),
  },
  created() {
    if (this.verifyRouteAddNewItem()) {
      this.createAgencyLicensing = true
      this.setSubtitle('')
    } else {
      this.fetchAgencyLicensign(this.$route.params.id)
    }
  },
  methods: {
    ...mapMutations({ setEdit: 'app/SET_ITEM_TABLE_EDIT', setSubtitle: 'app/SET_SUBTITLE', setlicensingAgencyData: 'publicAgencies/SET_LICENSING_AGENCY_DATA' }),
    fetchAgencyLicensign(id) {
      licensingAgency.getLicensingAgencyById(id).then(res => {
        this.licensingAgencyData = res.data
        this.setlicensingAgencyData(res.data)
        this.setSubtitle(this.licensingAgencyData.name)
      })
    },
    getDataCompanyByCnpj(cnpj) {
      this.getCompanyReceitaByCnpj(cnpj).then(res => {
        this.licensingAgencyData.name = res.nome

        const cep = res.cep.replace(/\D/gim, '')
        this.licensingAgencyData.zip_code = cep
        this.licensingAgencyData.city = titleInverse(res.municipio)
        this.licensingAgencyData.address = titleInverse(res.logradouro)
        this.licensingAgencyData.neighborhood = titleInverse(res.bairro)
        this.licensingAgencyData.state = res.uf
        this.licensingAgencyData.number_address = res.numero
        // this.getAddress(res.cep)
      })
    },
    saveChanges(form) {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.createAgencyLicensing) {
            licensingAgency.createLicensingAgency(form).then(res => {
              this.setEdit(false)
              this.toast({
                title: 'Criado!',
                text: 'Órgão licenciador criada com sucesso!',
                variant: 'success',
              })
              this.$router.push(`/orgaos-publicos/${res.data.id}/detalhes`)
            })
          } else {
            delete form.license_type
            delete form.updatedAt
            delete form.createdAt
            delete form.deletedAt

            licensingAgency.editLicensingAgency(form).then(() => {
              this.setEdit(false)
              this.toast({
                title: 'Atualizado!',
                text: 'Órgão licenciador atualizado com sucesso!',
                variant: 'success',
              })
            })
          }
          this.setSubtitle(this.licensingAgencyData.name)
        }
      })
    },

    cancel() {
      this.setEdit(false)
      if (this.createAgencyLicensing) {
        this.$router.push({ name: 'public-agencies' })
      } else {
        this.fetchAgencyLicensign(this.$route.params.id)
      }
    },

    getAddress(cep) {
      this.$refs.cep.validate().then(success => {
        if (success) {
          const address = this.getAdressByCep(cep)
          this.licensingAgencyData.address = address.logradouro
          this.licensingAgencyData.neighborhood = address.bairro
          this.licensingAgencyData.state = address.uf
          this.licensingAgencyData.city = address.localidade
          this.licensingAgencyData.number_address = address.complemento
        }
      })
    },
  },
}
</script>
