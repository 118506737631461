<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Dados gerais"
        active
      >
        <PublicAgenciesDetailsGeneralData />
      </b-tab>
      <div v-if="!verifyRouteAddNewItem()">
        <b-tab title="Tipos de licença">
          <PublicAgenciesDetailsProcess :items="licenseTypePublicAngencies.filter(arr => arr.isActive)" />
        </b-tab>
        <b-tab title="Contatos">
          <PublicAgenciesDetailsContacts
            :items="contactsPublicAngencies"
            relation="agency_id"
          />
        </b-tab>
        <b-tab>
          <template #title>
            Anexos
            <b-badge
              variant="primary"
              pill
              class="mr-1"
            >
              {{ attachmentsPublicAngencies.length }}
            </b-badge>
          </template>
          <PublicAgenciesDetailsAttachments
            :items="attachmentsPublicAngencies"
            relation="agency_id"
          />
        </b-tab>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BBadge } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import PublicAgenciesDetailsGeneralData from './PublicAgenciesDetailsGeneralData.vue'
import PublicAgenciesDetailsProcess from './PublicAgenciesDetailsProcess.vue'
import PublicAgenciesDetailsContacts from '@/components/contacts-details/DetailsContacts.vue'
import PublicAgenciesDetailsAttachments from '@/components/attachments-details/DetailsAttachments.vue'

export default {
  components: {
    BTabs,
    BTab,
    BBadge,

    PublicAgenciesDetailsGeneralData,
    PublicAgenciesDetailsProcess,
    PublicAgenciesDetailsContacts,
    PublicAgenciesDetailsAttachments,
  },
  computed: {
    ...mapGetters({ licenseType: 'publicAgencies/getLicensingAgencyData', contacts: 'contacts/getContacts', attachments: 'upload/getUpload' }),
    attachmentsPublicAngencies() {
      return this.attachments.filter(arr => arr.agency_id === Number(this.$route.params.id))
    },
    contactsPublicAngencies() {
      return this.contacts.filter(arr => arr.agency_id === Number(this.$route.params.id))
    },
    licenseTypePublicAngencies() {
      return this.licenseType ? this.licenseType.license_type : []
    },
  },
  created() {
    if (!this.verifyRouteAddNewItem()) {
      this.fetchLicensingAgencyById(this.$route.params.id)
      this.fetchContacts()
      this.fetchUploads()
    }
  },
  methods: {
    ...mapActions({ fetchLicensingAgencyById: 'publicAgencies/fetchLicensingAgencyById', fetchContacts: 'contacts/fetchContacts', fetchUploads: 'upload/fetchUploads' }),
  },
}
</script>
